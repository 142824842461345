export const todos = [
  {
    id: 1,
    userId: 1,
    label: `Прочитайте 3 новых книги`,
    done: false,
    important: false,
    createdAt: `2022-01-02T19:17:13.000Z`,
    updatedAt: `2022-01-02T19:17:13.000Z`,
  },
  {
    id: 2,
    userId: 1,
    label: `Научитесь делать бумажные самолётики`,
    done: false,
    important: false,
    createdAt: `2022-01-02T19:17:13.000Z`,
    updatedAt: `2022-01-02T19:17:13.000Z`,
  },
  {
    id: 3,
    userId: 1,
    label: `Займитесь своим хобби`,
    done: false,
    important: false,
    createdAt: `2022-01-02T19:17:13.000Z`,
    updatedAt: `2022-01-02T19:17:13.000Z`,
  },
  {
    id: 4,
    userId: 1,
    label: `Устройте пикник`,
    done: false,
    important: false,
    createdAt: `2022-01-02T19:17:13.000Z`,
    updatedAt: `2022-01-02T19:17:13.000Z`,
  },
  {
    id: 5,
    userId: 1,
    label: `Покатайтесь на колесе обозрения`,
    done: false,
    important: false,
    createdAt: `2022-01-02T19:17:13.000Z`,
    updatedAt: `2022-01-02T19:17:13.000Z`,
  },
];

export const a = null;
